.grid-section-selector {
    & > div > em {
        @apply invisible;
    }

    &:hover {
        & > div > em {
            @apply invisible;
        }
    }
}

.section-selected {
    & > div > em {
        @apply visible !important;
    }
}
